import { Chart } from 'chart.js';
import React from 'react';
import { Breakdown, ReportHeading, Statistic, BreakdownNotFound } from '../../helpers/uiComponents';
import { chartColors } from '../../helpers/uiHelpers';

class Roomvo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			quickStats: null,
            topRoom: null,
            topSku: null,
			loading: true,
			rendered: false,
			message: null,
        };
    }
	componentDidMount() {
		try {
			if (this.props.data) {
				this.handleData(this.props.data);
			}
		} catch (error) {
			console.log(error);
		}
	}
    componentDidUpdate(prevProps) {
    	// this.setState({hideHistorical: this.props.hideHistorical});
		try {
			if (this.props.data && (this.props.data !== prevProps.data)) {
                this.handleData(this.props.data);
			}
		} catch (error) {
			console.log(error);
		}
	}
	handleData(data) {
		data[2].shift();
		data[3].shift();
		
		this.setState({
			loading: false,
			message: null,
			quickStats: data[0],
			topRoom: data[2],
			topSku: data[3],
		});
		setTimeout(() => {
			if(data[1]) {
				this.setState({rendered: true,});
				this.buildDailyChart("Product Views", data[1], "views-graph"); /*title, inputData, elemId*/
			}
			if(data[2]) {
				if (data[2]) { this.buildPieChart(data[2], "Top Rooms Viewed", "topRoom-graph");}
				this.setState({topRoom: data[2],});
			}
			if(data[3]) {
				if (data[3]) { this.buildPieChart(data[3], "Top 10 Products Viewed", "topSku-graph");}
				this.setState({topSku: data[3],});
			}
		}, 500);
	}
    buildDailyChart(title, inputData, elemId) {
		if (inputData) {
			var data = [];
			var options = [...new Set(inputData.map(x => x[0]))];;			
            for (var i = inputData.length - 1; i >= 0; i--) {
				data.push(inputData[i][1]);
			}
			data.reverse(); //options.reverse();
			var ctx = document.getElementById(elemId);
			new Chart(ctx, {
				"type": "line",
				"data": {
					"labels":options,
					"datasets":[
						{
							"label": title,
							"data":data,
							"backgroundColor": "rgba(72, 152, 187, 0.3)",
							"borderColor": "rgba(72, 152, 187, 1)",
							"pointBackgroundColor": "rgba(190, 209, 47, 1)",
							"pointRadius": 5,
						}
					]
				},
				options: {
					aspectRatio: 1,
					maintainAspectRatio: false,
					legend: {display: false,},
					title: {
						display: true,
						text: title,
						fontSize: 16,
					},
					layout: {
			            padding: {left: 50, right: 50, top: 25, bottom: 25}
			        },
			        scales: {
			        	xAxes: [{ stacked: true }],
			        	yAxes: [{ stacked: true }]
			        },
				}
			});
		}
	}
    buildPieChart(inputData, title, elemId) {
		var data = [];
		var options = [];
		for (let i = 0; i < inputData.length; i++) {
			options.push(inputData[i][0]);
			data.push(inputData[i][1]);
		}
		// console.log(data);
		var ctx = document.getElementById(elemId);
		new Chart(ctx, {
			"type": "doughnut",
			"data": {
				"labels": options,
				"datasets":[{
					"data":data,
					"backgroundColor": chartColors,
				}]
			},
			options: {
				maintainAspectRatio: false,
				aspectRatio: 1,
				legend: {display: false,position: "bottom",},
				title: {
					display: true,
					text: title,
					fontSize: 16,
				},
				layout: {
					padding: {left: 50, right: 50, top: 25, bottom: 25}
				},
			}
		});
	}

    toggleTab(i, metric) {
		var menuItems = document.querySelectorAll('#metrics-roomvo .tab-menu .tab');
		for (var x = menuItems.length - 1; x >= 0; x--) {
			menuItems[x].classList.remove('active');
			menuItems[i-1].classList.add('active');
		}
		var graph = document.querySelector('.stats-graph.'+metric);
		var graphs = document.querySelectorAll('.stats-graph');
		for (var y = graphs.length - 1; y >= 0; y--) {
			graphs[y].classList.remove('active');
		}
		graph.classList.add('active');
	}

    render() {
        var hideHistorical = this.props.hideHistorical;
        const { message, loading, rendered, quickStats, topRoom, topSku } = this.state;
		
        return(
            <div id="metrics-roomvo" className="report metrics-roomvo">
                <ReportHeading
                    title="Room Visualizer"
                    name={this.props.currentClient.name}
                    dates={this.props.dates}
                    hideHistorical={hideHistorical}
                />
                {loading === true && (
                    <React.Fragment>
                        <h3>Fetching your Room Visualizer Statistics...</h3>
                        <div className="report-loading">
                            <p><i className="fas fa-circle-notch"></i></p>
                        </div>
                    </React.Fragment>
                )}
                {message && (
                    <React.Fragment>
                        <h3>We were unable to fetch your Room Visualizer Statistics.</h3>
                        <p>There may have been an error connecting to Roomvo, or Roomvo is not configured for your Portal.</p>
                        <p>Please contact your Account Manager for assistance.</p>
                    </React.Fragment>
                )}
                {(loading === false && !message) && (
                    <React.Fragment>
                        <section className="stats row">
							{(quickStats) && (
								quickStats.map((stat, index) => 
									<Statistic
										key={index}
										label={stat[0]}
										value={stat[1]}
										status={(stat[2] < 0) ? ("down") : ("up")}
										percent={(stat[2]) ? (stat[2]) : (0)}
										hideHistorical={hideHistorical}
									/>
								)
							)}
                        </section>

                        <section className="stats-graph">
                            <div className="graph wide roomvo">
                                <div className="graph-container views">
									{(!rendered) && (
										<div className="report-loading small">
											<p><i className="fas fa-circle-notch"></i></p>
										</div>
									)}
									<canvas className="chart" id="views-graph" />
                                </div>
                            </div>
                        </section>

                        <div className="tab-menu">
							<div onClick={() => this.toggleTab(1, "topRoom")} className="tab active">Top Rooms Viewed</div>
							<div onClick={() => this.toggleTab(2, "topSku")} className="tab">Top 10 Products Viewed (By SKU)</div>
						</div>
						<section className="stats-graph dimension topRoom active">
							{(topRoom) && (
								<React.Fragment>
									<div className="graph">
										<div className="graph-container">
											<canvas className="chart" id="topRoom-graph" />
										</div>
									</div>
									{/* <BreakdownNotFound /> */}
									<Breakdown
										data={topRoom}
										title={"Top Rooms Viewed"}
										format={"wide"}
										dataIndex={1} />
										
								</React.Fragment>
							)}
						</section>
						<section className="stats-graph dimension topSku">
							{(topSku) && (
								<React.Fragment>
									<div className="graph">
										<div className="graph-container">
											<canvas className="chart" id="topSku-graph" />
										</div>
									</div>
									{/*  <BreakdownNotFound />  */}
									<Breakdown
										data={topSku}
										title={"Top 10 Products Viewed (By SKU)"}
										format={"wide"}
										dataIndex={1}
									/>
								</React.Fragment>
							)}
						</section>
                    </React.Fragment>
                )}
            </div>
        )
    }
}
export default Roomvo;